const config = {
  apiKey: "AIzaSyCtlY88fmnAbNOZIRemBADFMvicXjKOjFQ",
  authDomain: "textyng.firebaseapp.com",
  projectId: "textyng",
  storageBucket: "textyng.appspot.com",
  messagingSenderId: "892488022331",
  appId: "1:892488022331:web:25a6af11d37b5ef3939f1e",
  measurementId: "G-X3VZXJEPLP"
};

export default config